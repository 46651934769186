<template>
  <div>
    <h3 class="modal__title">Terms of Use</h3>
    <div class="modal__body">
      <p>
        This document (the “Agreement”) is a legally binding agreement between
        you and DeepConvo Inc., a Delaware corporation, (“DeepConvo”) that
        governs your use of the online and mobile services associated with
        Telling.ai, including but not limited to, telling.ai and all associated
        subdomains (the “Website”). For ease of reference, all of the features
        and functionality of the Website, together with all of its content
        (whether accessible wirelessly, electronically or downloadable for
        printing), shall be referred to herein as the "System." The System is
        owned and managed by DeepConvo.
      </p>
      <p>
        Please note that you must read and agree to the terms and conditions of
        this Agreement before you use the System. If you do not agree to the
        terms and conditions of the Agreement, you may NOT use the System. The
        terms “we,” “us,” “our” and “ours” when used in these terms mean
        DeepConvo. The terms “you”, “your” and “yours” when used in these terms
        mean any user of the System.
      </p>
      <h4>1. Contract formation</h4>
      <p>
        By using the System you confirm that you are 18 years of age or more,
        that any registration information that you submit to Telling.ai is true,
        accurate and complete, that you will update such information in order to
        keep it current, that you have read and agree to the terms and
        conditions contained in this Agreement, our Privacy Policy and that you
        shall be legally bound by such terms subject to all applicable laws and
        regulations.
      </p>
      <h4>2. Understanding Telling.ai</h4>
      <p>
        You acknowledge and agree that the Telling.ai lung performance
        assessment is not intended to diagnose, treat or otherwise address any
        medical problem and the material on the System, whether posted by
        DeepConvo employees, or other users, is provided for your information
        and not as medical advice and should not be seen as a replacement for
        consultation with a doctor or other qualified healthcare professional.
      </p>
      <h4>3. Changes to the Agreement</h4>
      <p>
        DeepConvo reserves the right to change this Agreement from time to time
        by amending this page. Any material amendments intending to bind an
        existing user of the System shall become effective after notification
        has been displayed for 7 days on such user’s account settings page.
      </p>
      <h4>4. Your health and Telling.ai</h4>
      <p>
        This website is provided for informational purposes only. It is not
        intended to replace a consultation with an appropriately qualified
        health professional. If you are concerned about your health, please
        contact a health professional such as your primary care physician or
        calling your emergency service from any landline or mobile phone free of
        charge. To the fullest extent permitted by applicable laws, Telling.ai
        is not responsible for errors or omissions, or for any consequences
        arising from application of the information on the System, and make no
        warranty, express or implied, with respect to the contents, completeness
        or accuracy of the material published.
      </p>
      <h4>5. Privacy</h4>
      <p>
        We process information about you in accordance with our
        <a
          href="#"
          @click.prevent="showPrivacy"
        >Privacy Policy</a>. By using
        the System, you consent to such processing and you warrant that all data
        provided by you is accurate. You may be required to register personal
        information and establish unique usernames and passwords. You are
        responsible for maintaining the confidentiality of any passwords, and
        are fully responsible and or liable for all activities that occur under
        your password and username, whether by you or by anyone else using your
        identity. Should your password be compromised by breaches of security
        such as, but not limited to, loss, theft and unauthorized disclosure, it
        is your responsibility to immediately notify us of the need to change or
        deactivate the password. Until we are so notified you will remain liable
        for any unauthorized use of your account.
      </p>
      <h4>6. Intellectual property rights</h4>
      <p>
        The layout, design, content and graphics on the System and Telling.ai
        are protected by US and other international copyright laws. Unless
        expressly permitted in writing and other than printing a small
        proportion of content or displaying this on your screen or both,
        (strictly for your personal non-commercial use), no part of the System
        may be reproduced or stored in any medium, including but not limited to
        a retrieval system, or transmitted, in any form or by any means
        (electronic, mechanical, photocopying, recording, broadcasting), nor
        shown in public. You may not create any derivative work or make any
        other adaptation, without our prior written consent. You must not modify
        the copies you have displayed or printed in any way and you may not use
        any illustrations, photographs, videos or audio sequences or any
        graphics separately from any accompanying text. If you print off or
        download any material from the System in breach of these terms of use,
        your right to use the System and Telling.ai will cease immediately and
        you must at our option return or destroy any copies of the materials you
        have made. All rights not expressly granted in these terms or any
        express written license, are reserved. For all other uses of our content
        or images you must contact us and obtain our prior written permission.
        The Telling.ai logo and the name "Telling.ai" are trademarks owned by
        DeepConvo. Any use, including, but not limited to, framing, meta tags or
        other text using the Telling.ai logo or the Telling.ai name or other
        trademarks displayed, is strictly prohibited without our prior written
        consent.
      </p>
      <h4>7. Prohibited uses</h4>
      <p>You agree that you will not use the System to:</p>
      <ul class="list">
        <li>
          Upload, post, email or otherwise transmit any content or materials
          that are unlawful, harmful, threatening, abusive, harassing, tortious,
          defamatory, vulgar, obscene, libelous, invasive of another's privacy,
          hateful, or racially, ethnically or otherwise objectionable, at our
          sole and absolute discretion;
        </li>
        <li>
          Impersonate any person or entity, including, but not limited to, any
          user of this System, a director, officer, employee, shareholder, agent
          or representative of DeepConvo or our affiliates, or any other person
          or entity, or falsely state or otherwise misrepresent your affiliation
          with DeepConvo, our affiliates or any other person or entity;
        </li>
        <li>
          Forge headers or otherwise manipulate identifiers in order to disguise
          the origin of any Postings or other materials transmitted to or
          through the System;
        </li>
        <li>
          Upload, post, email or otherwise transmit any materials that are not
          your own, or that you do not have a right to upload, post, email or
          otherwise transmit under any law or under contractual or fiduciary
          relationships (such as insider information, proprietary and
          confidential information learned or disclosed as part of employment
          relationships or under nondisclosure agreements);
        </li>
        <li>
          Upload, post, email or otherwise transmit any content or other
          materials that infringe upon any patent, trademark, trade secret,
          copyright, right of privacy or publicity or other proprietary rights
          of any party; Upload, post, email or otherwise transmit any
          unsolicited or unauthorized advertising, promotional materials, "junk
          mail," "spam," "chain letters," "pyramid schemes," or any other form
          of commercial solicitation except in the areas, if any that are
          specifically designated for such purpose;
        </li>
        <li>
          Upload, post, email or otherwise transmit any content or other
          materials that contain software viruses or any other computer code,
          files or programs designed to interrupt, destroy or limit the
          functionality of any computer software or hardware or
          telecommunications equipment;
        </li>
        <li>
          Disrupt the normal flow of dialog, cause a screen to "scroll" faster
          than other users of the System are able to type, or otherwise act in a
          manner that negatively affects or otherwise diminishes the quality of
          another user’s experience of this System;
        </li>
        <li>
          Interfere with or disrupt the System or servers or networks connected
          to the System, or disobey any requirements, procedures, policies or
          regulations of networks connected to the System;
        </li>
        <li>
          Intentionally or unintentionally violate any applicable laws and/or
          regulations;
        </li>
        <li>
          "Stalk" or otherwise harass another user of the System and/or any
          employee of DeepConvo;
        </li>
        <li>
          Solicit, collect or post personal data or attempt to solicit, collect
          or post personal data about other users of the Website from the
          Website (including user names or passwords) or about any other third
          party; and/or Access or attempt to access another user’s account
          without his or her consent.
        </li>
        <li>
          Knowingly transmit any data, send or upload any material that contains
          viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware,
          adware or any other harmful programs or similar computer code designed
          to adversely affect the operation of any computer software or
          hardware.
        </li>
      </ul>
      <p>You also agree not to:</p>
      <ul class="list">
        <li>
          reproduce, duplicate, copy or re-sell any part of the System in
          contravention of the provisions of this Agreement;
        </li>
        <li>resell access to the System;</li>
        <li>
          frame the System or any part of it, copy any material obtained from
          the System to peer to peer networks or other web sites or web
          services;
        </li>
        <li>
          <span>
            access without authority, interfere with, damage or disrupt any
            part of the System:
          </span>
          <ul>
            <li>any equipment or network on which the System is stored;</li>
            <li>
              any software used in the provision of the System; or any equipment
              or network or software owned or used by any third party.
            </li>
          </ul>
        </li>
      </ul>
      <h4>8. Breach of the Agreement</h4>
      <p>
        We will determine, in our absolute discretion, whether there has been a
        breach of this Agreement through your use of the System. When a breach
        of this Agreement has occurred, we may take such action as we deem
        appropriate, which may include all or any of the following actions:
      </p>
      <ul class="list">
        <li>
          Immediate, temporary or permanent withdrawal of your right to use the
          System and/or the Telling.ai Service.
        </li>
        <li>
          Immediate, temporary or permanent removal of any posting or material
          uploaded by you to the System and/or the Telling.ai Service.
        </li>
        <li>
          Issue a warning notice to you to immediately cease and/or remedy such
          breach and following receipt such action should be taken immediately.
        </li>
        <li>
          Legal proceedings against you for reimbursement of all costs on an
          indemnity basis (including, but not limited to, reasonable
          administrative and legal costs) resulting from the breach.
        </li>
        <li>Any further legal action against you.</li>
        <li>
          Disclosure of such information to law enforcement authorities as we
          reasonably feel is necessary.
        </li>
      </ul>
      <p>
        We exclude liability for actions taken in response to breaches of this
        Agreement. The possible actions we may take on breach of this Agreement
        are not limited to those described here, and we may take any other
        action we reasonably deem appropriate.
      </p>
      <h4>9. No warranty</h4>
      <p>
        The use of the System and Telling.ai Service (including but not limited
        to their content and features) is at your own risk. The System is
        provided on an “as is” and “as available” basis. To the extent permitted
        by applicable law, DeepConvo gives no warranty, express or implied, as
        to the quality, content and availability or fitness for a specific
        purpose of the System or the Telling.ai Service or to the accuracy of
        the information contained in any of the materials on the System or the
        Telling.ai Service. Telling.ai shall not be liable to any person for any
        loss or damage of any kind, which may arise, directly or indirectly from
        the use of or inability to use any of the information contained in any
        of the materials on the System. There is no guarantee of availability of
        information on the System at any time, nor that it is up to date or
        error-free. As with any purchase of a product or service through any
        medium or in any environment, you should use your judgment and exercise
        caution where appropriate. No advice or information whether oral or in
        writing obtained by you from DeepConvo shall create any warranty on
        behalf of DeepConvo in this regard. Certain jurisdictions do not allow
        the exclusion or disclaimer of certain warranties. Accordingly some of
        the above disclaimers may not apply to you. Your statutory rights as a
        consumer, if any, are not affected hereby.
      </p>
      <h4>10. Limitation of liability</h4>
      <p>
        To the fullest extent applicable permitted by applicable laws,
        DeepConvo, its affiliates, officers, directors, employees, licensors or
        any third parties exclude liability for any direct, indirect,
        incidental, special or consequential damages (including but not limited
        to any loss of data, service interruption, computer failure or pecuniary
        loss, loss of income or revenue, loss of business, loss of profits or
        contracts, loss of anticipated savings, loss of data, loss of goodwill,
        wasted management or office time and for any other loss or damage of any
        kind, however arising and whether caused by tort (including negligence),
        breach of contract or otherwise, even if foreseeable) arising out of the
        use of or inability to use the System or Telling.ai Service, even if you
        have advised DeepConvo about the possibility of such loss, and including
        any damages resulting therefrom. Commentary and other materials posted
        on the System by other users are not intended to amount to advice on
        which reliance should be placed. We therefore, to the fullest extent
        permitted by applicable laws, disclaim all liability and responsibility
        arising from any reliance placed on such materials by any visitor to the
        System, or by anyone who may be informed of any of its contents. If any
        part of this limitation of liability is found to be invalid or
        unenforceable for any reason, then the aggregate liability of DeepConvo
        (including any of its affiliates, service providers and licensors) under
        such circumstance for liabilities that otherwise would have been
        limited, shall not exceed the maximum single purchase price of the
        Telling.ai Service.
      </p>
      <p>
        Nothing in this provision affects our or our contractors’ liability for
        death or personal injury arising from our (or their) negligence nor our
        (or their) liability for fraudulent misrepresentation or
        misrepresentation as to a fundamental matter nor any other liability
        which cannot be excluded or limited under an applicable law.
      </p>
      <h4>11. Technology limitations and modifications</h4>
      <p>
        DeepConvo will make reasonable efforts to keep the System operational.
        However, certain technical difficulties, maintenance, or any other cause
        beyond our reasonable control (including, but not limited to failure of
        the internet, natural disaster, labor shortage or dispute, or
        governmental act) may, from time to time, result in interruptions.
        DeepConvo reserves the right at any time and from time to time to modify
        or discontinue, temporarily or permanently, functions and features of
        the System with or without notice. You agree not to hold us liable for
        any such failure or delay in performance and we agree to make all
        reasonable efforts to restore the System (or any part thereof) as soon
        as practicable.
      </p>
      <h4>12. Third parties</h4>
      <p>
        We may make software owned or operated by third-party companies
        available to you. You must only use this software in accordance with the
        terms and conditions imposed by the third-party provider. Description of
        or reference to any organization, product, practitioner, publication or
        link to an external website does not imply endorsement by DeepConvo.
        Certain hypertext links in this site may lead to other third party
        websites, which are not under the control of DeepConvo. When you
        activate any of these you will leave the System and DeepConvo has no
        control over, and will accept no responsibility or liability, for the
        material on any website which is not under the control of DeepConvo.
        DeepConvo does not make any representations or give any guarantee or
        warranties of any kind, expressed, implied or otherwise about the
        content on any external website link.
      </p>
      <h4>13. Indemnity</h4>
      <p>
        You agree to indemnify and hold DeepConvo and its officers, directors,
        employees and licensors harmless from any claim or demand (including but
        not limited to reasonable legal fees) made by a third party due to or
        arising out of or related to your violation of the terms and conditions
        of this Agreement or your violation of the System (and all related
        materials) or any applicable laws, regulations or third party rights.
      </p>
      <h4>14. Assignment by DeepConvo</h4>
      <p>
        DeepConvo may assign (or otherwise transfer) this Agreement or any part
        of it without restrictions. You may not assign (or otherwise transfer)
        this Agreement or any part of it to any third party.
      </p>
      <h4>15. Entire agreement</h4>
      <p>
        This Agreement (incorporating these terms and the
        <a
          href="#"
          @click.prevent="showPrivacy"
        >Privacy Policy</a>
        as may be updated from time to time) constitutes all the terms and
        conditions agreed upon between you and DeepConvo and supersede any prior
        agreements in relation to the subject matter of this Agreement, whether
        written or oral. Any additional or different terms or conditions in
        relation to the subject matter of this Agreement in any written or oral
        communication from you to DeepConvo are void. You agree and accept that
        you have not accepted the terms and conditions of this Agreement in
        reliance of or to any oral or written representations made by DeepConvo
        not contained in this Agreement.
      </p>
      <h4>16. Severability and waiver</h4>
      <p>
        If any provision of the Agreement is held by a court of competent
        jurisdiction to be unlawful, void or for any reason unenforceable, such
        provision shall be changed and interpreted so as to best accomplish the
        objectives of the original provision to the fullest extent permitted by
        law, and the remaining provisions of the Agreement shall remain in full
        force and effect. If we fail to insist that you perform any of your
        obligations under these terms, or if we do not enforce our rights
        against you, or if we delay in doing so, that will not mean that we have
        waived our rights against you and will not mean that you do not have to
        comply with those obligations. If we do waive a default by you, we will
        only do so in writing, and that will not mean that we will automatically
        waive any later default by you.
      </p>
      <h4>17. Term and termination</h4>
      <p>
        This Agreement is between you and us only and will become effective when
        you start using the System and will remain effective until terminated by
        you or DeepConvo. DeepConvo reserves the right to terminate this
        Agreement or suspend your Telling.ai account at any time in case of
        unauthorized, or suspected unauthorized use of the System whether in
        contravention of this Agreement or otherwise. If DeepConvo terminates
        this Agreement, or suspends your Telling.ai account, for any of the
        reasons set out in this section, DeepConvo shall have no liability or
        responsibility to you whatsoever, and DeepConvo shall not refund any
        amounts that you have paid.
      </p>
      <h4>18. Governing law and disputes</h4>
      <p>
        The System is controlled by DeepConvo from its offices in the United
        States. Access to, or use of, the System, including the Telling.ai
        Service and any related information and materials, may be prohibited by
        law in certain jurisdictions. You are responsible for compliance with
        all applicable laws of the jurisdiction from which you are accessing the
        System. We make no representation that the information contained herein
        is appropriate or available for use in other locations. This Agreement
        and any contract between us, whether for use of the System or other
        purpose, and any non-contractual obligations (if any) arising out of or
        in connection with these terms and conditions or any such contract will
        be governed by the laws of the state of Delaware. The parties agree that
        the courts of Delaware have exclusive jurisdiction to settle any dispute
        arising out of or in connection with this Agreement (including a dispute
        regarding the existence, validity or termination of this Agreement or
        any non-contractual obligation arising out of or in connection with this
        Agreement). Notwithstanding the foregoing, we reserve the right to bring
        legal proceedings in any jurisdiction where we believe a breach of this
        Agreement has originated.
      </p>
      <h4>19. Survivorship</h4>
      <p>
        The following provisions shall survive termination of this Agreement:
        Clause 6 (Intellectual property), Clause 10 (Limitation), Clause 13
        (Indemnity), Clause 15 (Entire agreement), Clause 16 (Severability and
        waiver) and Clause 18 (Governing law and disputes).
      </p>
      <h4>20. Contact</h4>
      <p>
        You may contact us at
        <a href="mailto:TOS@telling.ai">TOS@telling.ai</a>.
      </p>
      <p>
        Please note that we are unable to answer any enquiries requesting
        medical advice. Such enquiries should be addressed to an appropriate,
        qualified health practitioner.
      </p>
      <div class="modal__footer">
        <button class="button button_secondary button_md" @click="$emit('close')">close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { POPUP_PRIVACY } from "@/constants";
import { saveLog } from "@/modules/logger";

export default {
  inject: {
    showModal: { default: function () {} },
  },

  mounted() {
    saveLog("pop-up-tos");
  },

  methods: {
    showPrivacy() {
      this.showModal(POPUP_PRIVACY);
    },
  },
};
</script>
